<template>
  <div class="container">
    <!-- 顶部navbar -->
    <div class="navbar">
      <div class="content">
        <img src="../../assets/img/logo.png" alt="" />
        <div class="navbar-right">
          <div class="navbar-item">
            <a href="javascript:;">下载APP</a>
            <img
              src="../../assets/img/xiala.png"
              alt=""
              @mouseenter="isShow = true"
              @mouseleave="isShow = false"
            />
            <img
              v-show="isShow"
              class="download-qr"
              :src="net + about_service.app_download"
              alt=""
              style="background: #fff; padding: 2px"
            />
          </div>
          <div class="navbar-item">
            <router-link to="/aboutUs">关于我们</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/login">登录</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/register">注册</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <h4>隐私协议</h4>
      <!-- <h5>作者：空想一</h5> -->
      <!-- <div class="date">{{ text.update_at }}</div> -->
      <div class="text" v-html="text.content"></div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import { getAgreement } from "@network/agreement";
import { getHome } from "@network/home";
export default {
  name: "PrivacyAgreement",
  data() {
    return {
      text: {},
      net: BASE_URL,
      downloadData: {
        url: window.location.href,
      },
      about_service: {}, //关于和服务
      isShow: false,
    };
  },

  created() {
    this.getAgreement();
    this.getHome();
  },

  components: {},

  methods: {
    format(num) {
      if (num < 10) return "0" + num;
      return num;
    },
    getHome() {
      getHome().then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message({
            message:"获取失败",
            type:"error",
            offset:300
          });
          return false;
        } else {
          this.about_service = res.data.homePage;
        }
      });
    },

    getAgreement() {
      getAgreement("2").then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message({
            message:"获取失败",
            type:"error",
            offset:300
          });
          return false;
        }
        if (res.data.content.indexOf("img") !== -1) {
          var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
          var s = res.data.content.match(b); // 取到所有img标签 放到数组 s里面
          for (var i = 0; i < s.length; i++) {
            var srcImg = s[i].replace(b, "$1"); //取src面的内容
            if (srcImg.slice(0, 4) == "http" || srcImg.slice(0, 5) == "https") {
              //若src前4位置或者前5位是http、https则不做任何修改
              //console.log("不做任何修改");
            } else {
              //修改富文本字符串内容 img标签src 相对路径改为绝对路径
              res.data.content = res.data.content.replace(
                new RegExp(srcImg, "g"),
                this.net + srcImg
              );
            }
          }
        }
        if (res.data.content.indexOf("video") !== -1) {
          var b = /<video [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
          var s = res.data.content.match(b); // 取到所有img标签 放到数组 s里面
          for (var i = 0; i < s.length; i++) {
            var srcImg = s[i].replace(b, "$1"); //取src面的内容
            if (srcImg.slice(0, 4) == "http" || srcImg.slice(0, 5) == "https") {
              //若src前4位置或者前5位是http、https则不做任何修改
              //console.log("不做任何修改");
            } else {
              //修改富文本字符串内容 img标签src 相对路径改为绝对路径
              res.data.content = res.data.content.replace(
                new RegExp(srcImg, "g"),
                this.net + srcImg
              );
            }
          }
        }
        res.data.update_at =
          new Date(res.data.update_at * 1000).getFullYear() +
          "-" +
          this.format(new Date(res.data.update_at * 1000).getMonth() + 1) +
          "-" +
          this.format(new Date(res.data.update_at * 1000).getDate()) +
          " " +
          this.format(new Date(res.data.update_at * 1000).getHours()) +
          ":" +
          this.format(new Date(res.data.update_at * 1000).getMinutes()) +
          ":" +
          this.format(new Date(res.data.update_at * 1000).getSeconds());
        this.text = res.data;
      });
    },
  },

  computed: {},
};
</script>
<style lang='less' scoped>
.navbar {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  .content {
    width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 128px;
    }
    .navbar-right {
      display: flex;
      .navbar-item {
        position: relative;
        cursor: pointer;
        margin: 0 60px;
        a {
          font-size: 18px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #333333;
          line-height: 25px;
        }
        img {
          vertical-align: middle;
          width: 16px;
          height: 16px;
        }
        .download-qr {
          z-index: 9;
          transform: translateY(35%);
          position: absolute;
          top: 0;
          left: -15px;
          width: 90px;
          height: 90px;
        }
      }
    }
  }
}
.container {
  position: relative;
  min-height: calc(100vh - 272px);
  .content {
    padding: 20px 0;
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    h4 {
      font-size: 26px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #333333;
      line-height: 37px;
      margin-bottom: 20px;
    }
  }
  .text /deep/ img {
    max-width: 800px;
  }
}
</style>